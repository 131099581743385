import { TypeManagerDecorator } from "../../../main/type.map.service";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Season } from "../seasons/seasons.service";
import { AddressType, MatchProfile, StudentHousingService } from "../student.housing.service";
import { S25Util } from "../../../util/s25-util";
import { CellData, RowData } from "../../s25-spreadsheet/types/sheet.js.types";

@TypeManagerDecorator("s25-ng-season-invite")
@Component({
    selector: "s25-ng-season-invite",
    template: `
        <div style="max-width: 50%">
            <p>
                Add users to a match season by uploading a spreadsheet with the following headers and respective data:
                Internal Id, First Name, Last Name, Email, Phone, Segment, Match Group.
            </p>
            <p class="c-margin-top--single c-margin-bottom--single">
                Note: the Internal Id column should be an immutable, unique, identifier from your system, such as a
                student id.
            </p>
            <p class="c-margin-top--single c-margin-bottom--single">
                Duplicate contacts will not be created with the same Internal Id, but will instead be updated if they
                have the same Internal Id.
            </p>
            <s25-ng-season-dropdown
                class="ngBlock c-margin-bottom--single"
                [(selectedSeason)]="season"
            ></s25-ng-season-dropdown>
            @if (season) {
                <div class="c-margin-bottom--single">
                    <div class="ngFinePrint">
                        Only check if you are re-uploading existing profiles and need match group updated.
                    </div>
                    <s25-ng-checkbox [(modelValue)]="updateMatchGroup">Update Match Group</s25-ng-checkbox>
                </div>

                <div class="c-margin-bottom--single">
                    <div class="ngFinePrint">
                        Only check if you are moving profiles from one already fully processed season to another season
                        for processing. Note that this will not impact any events.
                    </div>
                    <s25-ng-checkbox [(modelValue)]="removeProfileAssignments"
                        >Remove Profile Assignments</s25-ng-checkbox
                    >
                </div>

                <s25-ng-spreadsheet
                    class="c-margin-top--single"
                    [onComplete]="onComplete"
                    [completeText]="'Create Profiles'"
                ></s25-ng-spreadsheet>
            }
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeasonInviteComponent implements OnInit {
    season: Season;
    init = false;
    expectedColumnCount = 7;
    updateMatchGroup = false;
    removeProfileAssignments = false;

    constructor(public cd: ChangeDetectorRef) {}

    onComplete = (rows: RowData[]) => {
        if (!rows || !rows.length) {
            return;
        }
        let profiles = this.rowsToProfiles(rows);
        return StudentHousingService.addProfiles(profiles, this.updateMatchGroup, this.removeProfileAssignments);
    };

    rowsToProfiles = (rows: RowData[]) => {
        let profiles: MatchProfile[] = [];
        let cellNumToHeader: Map<number, String> = new Map<number, String>();

        if (!rows || !rows.length) {
            return null;
        }

        for (let r = 0; r < rows.length; r++) {
            if (!rows[r] || !rows[r].cells || !rows[r].cells.length) {
                continue;
            }

            if (r === 0) {
                // headers
                for (let c = 0; c < rows[r].cells.length; c++) {
                    let cell = rows[r].cells[c];
                    cellNumToHeader.set(c, cell.text);
                }
                continue;
            }

            let profile = this.cellsToProfile(rows[r].cells, cellNumToHeader);
            profile && profiles.push(profile);
        }

        return profiles;
    };

    cellsToProfile = (cells: CellData[], cellNumToHeader: Map<number, String>) => {
        let profile: MatchProfile = {
            seasonId: this.season.seasonId,
            inviteHash: S25Util.randomUUID(),
            contact: {
                addresses: [
                    {
                        addrType: AddressType.WORK,
                    },
                ],
            },
            status: "questions",
        };

        let valueCount = 0;
        for (let c = 0; c < cells.length; c++) {
            let cell = cells[c];
            let headerName = cellNumToHeader.get(c).toLowerCase();
            if (headerName.indexOf("internal") > -1) {
                valueCount++;
                profile.contact.internalId = cell.text;
            } else if (headerName.indexOf("first") > -1) {
                valueCount++;
                profile.contact.firstName = cell.text;
            } else if (headerName.indexOf("last") > -1) {
                valueCount++;
                profile.contact.familyName = cell.text;
            } else if (headerName.indexOf("email") > -1) {
                valueCount++;
                profile.contact.addresses[0].email = cell.text;
            } else if (headerName.indexOf("phone") > -1) {
                valueCount++;
                profile.contact.addresses[0].phone = cell.text;
            } else if (headerName.indexOf("segment") > -1) {
                valueCount++;
                profile.segment = cell.text;
            } else if (headerName.indexOf("group") > -1) {
                valueCount++;
                profile.matchGroup = cell.text;
            }
        }

        if (valueCount === this.expectedColumnCount) {
            return profile;
        }
        return null;
    };

    ngOnInit() {
        this.init = true;
        this.cd.detectChanges();
    }
}
