import { TypeManagerDecorator } from "../../../main/type.map.service";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { S25ItemI } from "../../../pojo/S25ItemI";
import { MultiselectModelI } from "../../s25-multiselect/s25.multiselect.component";
import { Season } from "../seasons/seasons.service";
import { MatchRoomMatchGroup, MatchStudentHousing, StudentHousingService } from "../student.housing.service";
import { S25Util } from "../../../util/s25-util";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { SpaceService } from "../../../services/space.service";
import { Table } from "../../s25-table/Table";
import { S25TableComponent } from "../../s25-table/s25.table.component";

@TypeManagerDecorator("s25-ng-student-housing-buildings")
@Component({
    selector: "s25-ng-student-housing-buildings",
    template: `
        <s25-ng-season-dropdown
            class="ngBlock c-margin-bottom--single"
            [selectedSeason]="buildingSeason"
            (selectedSeasonChange)="onBuildingSeasonChange($event)"
        ></s25-ng-season-dropdown>
        @if (seasonSelected) {
            <s25-ng-multiselect-search-criteria
                [type]="'buildings'"
                [modelBean]="buildingModel"
                [selectedItems]="studentBuildings"
                [popoverPlacement]="'right'"
                [useSecurity]="true"
            ></s25-ng-multiselect-search-criteria>

            <h3 class="c-margin-top--single">Building Info</h3>
            <div class="sortContainer c-margin-top--half">
                <ul s25-ng-dnd-sortable [items]="buildingModel.selectedItems">
                    @for (bldg of buildingModel.selectedItems; track bldg; let i = $index) {
                        <li s25-ng-dnd-sortable-item [index]="i">
                            <s25-ng-drag-handle></s25-ng-drag-handle>
                            <div class="sortableItemContainer">
                                <span>{{ bldg.itemName }}</span>
                                <div class="c-margin-top--half sortableItemData">
                                    <s25-ng-dropdown-multi-search-criteria
                                        [(chosen)]="buildingMatchGroups[bldg.itemId]"
                                        [type]="'matchGroups'"
                                        [customFilterValue]="$any(buildingSeason.seasonId)"
                                    ></s25-ng-dropdown-multi-search-criteria>
                                </div>
                                <div class="c-margin-top--half sortableItemData">
                                    <label
                                        >Message
                                        <input type="text" class="c-input" [(ngModel)]="buildingMessages[bldg.itemId]"
                                    /></label>
                                </div>
                            </div>
                        </li>
                    }
                </ul>
            </div>
            <s25-ng-button [buttonClass]="'c-margin-top--single'" [type]="'primary'" [onClick]="setBuildings"
                >Save Buildings</s25-ng-button
            >

            <div class="c-margin-top--single">
                <s25-ng-search-dropdown
                    [hasFav]="true"
                    [(chosen)]="locationSearch"
                    [itemTypeId]="4"
                    [allowNonQueryId]="false"
                    [searchEnabled]="true"
                    [placeholder]="'Location Search'"
                ></s25-ng-search-dropdown>

                <s25-ng-dropdown-multi-search-criteria
                    [(chosen)]="roomMatchGroups"
                    [type]="'matchGroups'"
                    [customFilterValue]="$any(buildingSeason.seasonId)"
                ></s25-ng-dropdown-multi-search-criteria>

                <s25-ng-button
                    [buttonClass]="'c-margin-top--single'"
                    [type]="'primary'"
                    [onClick]="addRoomGroups"
                    [errHandler]="errHandler"
                    >Set Match Groups</s25-ng-button
                >

                <s25-ng-button
                    [buttonClass]="'c-margin-top--single'"
                    [type]="'danger'"
                    [onClick]="deleteRoomGroups"
                    [errHandler]="errHandler"
                    >Delete Match Groups</s25-ng-button
                >
            </div>

            <div class="c-margin-top--double">
                <h3 [style.margin-bottom]="'-20px'">Rooms by Match Group</h3>
                <s25-ng-table
                    [caption]="'Rooms Match Groups'"
                    [unlimitedWidth]="true"
                    [columnSortable]="true"
                    [dataSource]="roomMatchGroupsDataSource"
                    [hasTotalRowCount]="true"
                    [hasRefresh]="true"
                    [showHeaderWhenNoData]="true"
                ></s25-ng-table>
            </div>
        }
    `,
    styles: `
        .sortContainer ul {
            padding-inline-start: 0;
        }

        .sortContainer ul li {
            list-style-type: none;
            padding: 0.5em 0;
            border-bottom: 1px solid #ddd;
            display: flex;
        }

        .sortableItemContainer {
            display: flow-root;
        }

        .sortableItemData {
            display: flex;
            flex-direction: row;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentHousingBuildingsComponent {
    @ViewChild(S25TableComponent) table: S25TableComponent;

    constructor(private cd: ChangeDetectorRef) {}

    studentBuildings: S25ItemI[];
    buildingModel: MultiselectModelI = { showResult: true };
    seasonSelected = false;
    buildingSeason: Season;
    buildingMatchGroups: { [key: number]: DropDownItem[] } = {};
    buildingMessages: { [key: number]: string } = {};
    locationSearch: DropDownItem;
    roomMatchGroups: DropDownItem[] = [];
    roomMatchGroupsDataSource: Table.Unpaginated = {
        type: "unpaginated",
        dataSource: async () => {
            let rooms = await StudentHousingService.getRoomGroups(this.buildingSeason.seasonId);
            return {
                rows: rooms.map((r) => {
                    return {
                        id: r.roomId + "&" + r.matchGroup,
                        name: r.roomId + "&" + r.matchGroup,
                        cells: {
                            roomId: {
                                text: r.roomId + "",
                            },
                            roomName: {
                                text: r.room?.roomShort ?? "",
                            },
                            matchGroup: {
                                text: r.matchGroup,
                            },
                        },
                        data: r,
                    };
                }),
                totalRows: rooms.length,
            };
        },
        columns: [
            {
                id: "roomId",
                header: "Room Id",
                sortable: true,
            },
            {
                id: "roomName",
                header: "Room Name",
                sortable: true,
            },
            {
                id: "matchGroup",
                header: "Match Group",
                sortable: true,
            },
        ],
    };

    onBuildingSeasonChange = (season: Season) => {
        this.seasonSelected = false;
        this.cd.detectChanges();
        this.buildingSeason = season;
        StudentHousingService.getStudentBuildings(season.seasonId).then((studentBuildings) => {
            studentBuildings.forEach((bldg) => {
                this.buildingMatchGroups[bldg.bldgId] = (bldg.matchGroups ?? []).map((mg) => {
                    return {
                        itemId: mg.matchGroup,
                        itemName: mg.matchGroup,
                        val: mg.matchGroup,
                    };
                });
                this.buildingMessages[bldg.bldgId] = bldg.buildingMsg;
            });
            this.studentBuildings = (studentBuildings || []).map((b: MatchStudentHousing) => {
                return {
                    itemId: b.bldgId,
                    itemName: b.building.bldgName,
                    itemDesc: b.building.bldgCode,
                    sortOrder: b.sortOrder,
                };
            });
            this.seasonSelected = true;
            this.cd.detectChanges();
            this.refreshTable();
        });
    };

    move = (direction: number, bldg: S25ItemI) => {
        let idx = this.buildingModel.selectedItems.indexOf(bldg);
        let newIdx = idx + direction;
        if (newIdx < 0 || newIdx >= this.buildingModel.selectedItems.length) return;
        this.buildingModel.selectedItems.splice(idx, 1, this.buildingModel.selectedItems[newIdx]);
        this.buildingModel.selectedItems.splice(newIdx, 1, bldg);
        this.cd.detectChanges();
    };

    setBuildings = () => {
        return StudentHousingService.setStudentBuildings(
            this.buildingSeason.seasonId,
            this.buildingModel.selectedItems.map((b, i) => {
                let bldgId = S25Util.parseInt(b.itemId);
                return {
                    seasonId: this.buildingSeason.seasonId,
                    bldgId: bldgId,
                    sortOrder: i + 1,
                    buildingMsg: this.buildingMessages[bldgId] || "",
                    matchGroups: (this.buildingMatchGroups[bldgId] || []).map((mg) => {
                        return {
                            bldgId: bldgId,
                            seasonId: this.buildingSeason.seasonId,
                            matchGroup: S25Util.toStr(mg.itemName),
                        };
                    }),
                };
            }),
        );
    };

    getRoomGroups = async () => {
        if (!this.roomMatchGroups.length) {
            return Promise.reject("No match groups found");
        }
        let spaces = await SpaceService.getSpacesBySearchQuery(
            "&query_id=" + this.locationSearch.itemId,
            [],
            "minimal",
        );
        let spacesArr = spaces?.spaces?.space || [];
        if (!spacesArr.length) {
            return Promise.reject("No locations found for location search");
        }
        let roomMatchGroups: MatchRoomMatchGroup[] = [];
        spacesArr.forEach((space: any) => {
            this.roomMatchGroups.forEach((mg) => {
                roomMatchGroups.push({
                    seasonId: this.buildingSeason.seasonId,
                    roomId: space.space_id,
                    matchGroup: S25Util.toStr(mg.itemName),
                });
            });
        });
        return roomMatchGroups;
    };

    addRoomGroups = async () => {
        await StudentHousingService.addRoomGroups(this.buildingSeason.seasonId, await this.getRoomGroups());
        this.roomMatchGroups.splice(0);
        await this.refreshTable();
    };

    deleteRoomGroups = async () => {
        await StudentHousingService.deleteRoomGroups(this.buildingSeason.seasonId, await this.getRoomGroups());
        this.roomMatchGroups.splice(0);
        await this.refreshTable();
    };

    errHandler = (err: any) => {
        err && S25Util.showError(err);
    };

    refreshTable = async () => {
        this.cd.detectChanges();
        await this.table.refresh();
        this.cd.detectChanges();
    };
}
