import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { Question, StudentHousingService } from "../student.housing.service";
import { Season } from "../seasons/seasons.service";
import { Rules } from "../../s25-rule-tree/s25.rule.const";
import { EventService } from "../../../services/event.service";
import { StudentHousingConst } from "./student.housing.const";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { S25Util } from "../../../util/s25-util";

export type StudentHousingAdminMode =
    | "initialize"
    | "seasons"
    | "buildings"
    | "form"
    | "matchRules"
    | "profileManagement"
    | "profileManagement.invite"
    | "profileManagement.adminSearch"
    | "email"
    | "process";

@TypeManagerDecorator("s25-ng-student-housing-admin")
@Component({
    selector: "s25-ng-student-housing-admin",
    template: `
        @if (init) {
            <div>
                <div class="cust-attr-menu">
                    <ul>
                        <li [ngClass]="{ active: mode === 'initialize' }">
                            <a href="javascript:void(0)" (click)="setMode('initialize')">Initialize</a>
                        </li>
                        <li [ngClass]="{ active: mode === 'seasons' }">
                            <a href="javascript:void(0)" (click)="setMode('seasons')">Seasons</a>
                        </li>
                        <li [ngClass]="{ active: mode === 'buildings' }">
                            <a href="javascript:void(0)" (click)="setMode('buildings')">Buildings</a>
                        </li>
                        <li [ngClass]="{ active: mode === 'profileManagement' }">
                            <a href="javascript:void(0)" (click)="setMode('profileManagement')">Profile Management</a>
                            <ul *s25IfShow="mode.startsWith('profileManagement')" class="sidebar-submenu expanded">
                                <li [ngClass]="{ active: mode === 'profileManagement.adminSearch' }">
                                    <a href="javascript:void(0)" (click)="setMode('profileManagement.adminSearch')"
                                        >Admin Search</a
                                    >
                                </li>
                                <li [ngClass]="{ active: mode === 'profileManagement.invite' }">
                                    <a href="javascript:void(0)" (click)="setMode('profileManagement.invite')">Add</a>
                                </li>
                            </ul>
                        </li>
                        <li [ngClass]="{ active: mode === 'form' }">
                            <a href="javascript:void(0)" (click)="setMode('form')">Form</a>
                        </li>
                        <li [ngClass]="{ active: mode === 'matchRules' }">
                            <a href="javascript:void(0)" (click)="setMode('matchRules')">Match Groups</a>
                        </li>
                        <li [ngClass]="{ active: mode === 'email' }">
                            <a href="javascript:void(0)" (click)="setMode('email')">Email</a>
                        </li>
                        <li [ngClass]="{ active: mode === 'process' }">
                            <a href="javascript:void(0)" (click)="setMode('process')">Process</a>
                        </li>
                    </ul>
                </div>

                <div *s25IfShow="mode === 'initialize'">
                    <p class="c-margin-bottom--single">
                        Click Initialize below to create a Student Housing cabinet and folder structure. After
                        initializing, visit the Groups Tool to set up default OLS for Student Housing assignments.
                    </p>
                    @if (alreadyInitialized) {
                        <p class="c-margin-bottom--single">Student Housing has already been initialized.</p>
                    }
                    <s25-ng-button [type]="'primary'" [onClick]="initialize">Initialize</s25-ng-button>
                </div>

                <div *s25IfShow="mode === 'seasons'">
                    <s25-ng-seasons></s25-ng-seasons>
                </div>

                <div *s25IfShow="mode === 'buildings'">
                    <s25-ng-student-housing-buildings></s25-ng-student-housing-buildings>
                </div>

                <div *s25IfShow="mode === 'form'">
                    <s25-ng-season-dropdown
                        class="ngBlock c-margin-bottom--single"
                        [selectedSeason]="formSeason"
                        (selectedSeasonChange)="onFormSeasonChange($event)"
                    ></s25-ng-season-dropdown>
                    @if (formSeason) {
                        <s25-ng-match-question [seasonId]="formSeason.seasonId"></s25-ng-match-question>
                    }
                </div>

                <div *s25IfShow="mode === 'matchRules'">
                    <s25-ng-season-dropdown
                        class="ngBlock c-margin-bottom--single"
                        [selectedSeason]="ruleSeason"
                        (selectedSeasonChange)="onRuleSeasonChange($event)"
                    ></s25-ng-season-dropdown>
                    @if (ruleSeason) {
                        <s25-ng-rule-tree
                            [category]="'match'"
                            [subCategory]="subCategory"
                            [conditionFilterMap]="conditionFilterMap"
                        ></s25-ng-rule-tree>

                        <div class="c-margin-top--single">
                            <label
                                >New Match Group
                                <input type="text" [(ngModel)]="newMatchGroup" />
                            </label>
                            <s25-ng-button [type]="'primary'" [onClick]="addMatchGroup">Add Match Group</s25-ng-button>
                        </div>

                        @if (matchGroupDropdown) {
                            <div class="c-margin-top--single">
                                <s25-ng-dropdown-search-criteria
                                    [(chosen)]="matchGroupToDelete"
                                    [type]="'matchGroups'"
                                    [customFilterValue]="$any(ruleSeason.seasonId)"
                                ></s25-ng-dropdown-search-criteria>
                                <s25-ng-button [type]="'danger'" [onClick]="deleteMatchGroup"
                                    >Delete Match Group</s25-ng-button
                                >
                            </div>
                        }
                    }
                </div>

                <div *s25IfShow="mode.startsWith('profileManagement')">
                    <s25-ng-admin-profile-list
                        *s25IfShow="mode === 'profileManagement.adminSearch'"
                    ></s25-ng-admin-profile-list>
                    <s25-ng-season-invite *s25IfShow="mode === 'profileManagement.invite'"></s25-ng-season-invite>
                </div>

                <div *s25IfShow="mode === 'email'">
                    <s25-ng-student-housing-email></s25-ng-student-housing-email>
                </div>

                <div *s25IfShow="mode === 'process'">
                    <s25-ng-season-process></s25-ng-season-process>
                </div>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentHousingAdminComponent implements OnInit {
    constructor(private cd: ChangeDetectorRef) {}

    formSeason: Season;
    ruleSeason: Season;
    conditionFilterMap: Rules.ConditionFilterMap;
    subCategory: string;
    questions: Question[];
    mode: StudentHousingAdminMode = "initialize";
    newMatchGroup: string = "";
    matchGroupToDelete: DropDownItem = null;
    matchGroupDropdown = true;
    alreadyInitialized = false;
    init = false;

    setMode = (mode: StudentHousingAdminMode = "seasons") => {
        this.mode = mode;
        if (this.mode === "profileManagement") {
            this.setMode("profileManagement.adminSearch");
        }
        this.cd.detectChanges();
    };

    onFormSeasonChange = (season: Season) => {
        this.formSeason = null;
        this.cd.detectChanges();
        this.formSeason = season;
        this.cd.detectChanges();
    };

    onRuleSeasonChange = async (season: Season) => {
        this.questions = await StudentHousingService.getQuestions(season.seasonId);
        this.ruleSeason = null;
        this.cd.detectChanges();
        this.ruleSeason = season;
        this.conditionFilterMap = {
            matchQuestions: "" + season.seasonId,
            matchQuestionsAll: "" + season.seasonId,
            nonTextMatchQuestions: "" + season.seasonId,
            matchGroups: "" + season.seasonId,
        };
        this.subCategory = "" + season.seasonId;
        this.cd.detectChanges();
    };

    initialize = async () => {
        return await StudentHousingService.initialize();
    };

    isAlreadyInitialized = () => {
        return EventService.getEventTypeById(StudentHousingConst.EventType).then(
            (type) => {
                return !!type;
            },
            () => {
                return false;
            },
        );
    };

    addMatchGroup = async () => {
        if (!this.newMatchGroup) return S25Util.showError(null, "Match Group name is required");
        const [_, err] = await S25Util.Maybe(
            StudentHousingService.addMatchGroup(this.ruleSeason.seasonId, this.newMatchGroup),
        );
        this.newMatchGroup = "";
        this.cd.detectChanges();
        err && S25Util.showError(err);
        this.resetMatchGroupDropdown();
    };

    deleteMatchGroup = async () => {
        if (!this.matchGroupToDelete) return S25Util.showError(null, "Match Group is required");
        const [_, err] = await S25Util.Maybe(
            StudentHousingService.deleteMatchGroup(this.ruleSeason.seasonId, this.matchGroupToDelete.itemName),
        );
        this.matchGroupToDelete = null;
        this.cd.detectChanges();
        err && S25Util.showError(err);
        this.resetMatchGroupDropdown();
    };

    resetMatchGroupDropdown = () => {
        this.matchGroupToDelete = null;
        this.matchGroupDropdown = false;
        this.cd.detectChanges();
        this.matchGroupDropdown = true;
        this.cd.detectChanges();
    };

    async ngOnInit() {
        this.alreadyInitialized = await this.isAlreadyInitialized();
        this.init = true;
        this.cd.detectChanges();
    }
}
